<template>
  <div>
    <a-card :title="$t('货品列表')">
      <template slot="extra">
        <a-button type="primary" icon="left" ghost style="margin: -4px 0" @click="$router.go(-1)">{{ $t("返回") }}</a-button>
      </template>

      <a-row gutter="16">
        <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <CategorySelect v-model="searchForm.category" :placeholder="$t('分类')" @change="search" />
        </a-col>
        <a-col :span="24" :md="6" :xl="4" style="max-width: 256px; margin-bottom: 12px">
          <a-input v-model="searchForm.search" :placeholder="$t('货品编号, 名称')" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" :md="8" :xl="6" style="max-width: 100px; margin-bottom: 12px">
          <a-button type="primary" icon="search" @click="search">{{ $t("查询") }}</a-button>
        </a-col>

        <a-col :span="24" :md="8" :xl="10" style="margin-bottom: 12px">
          <a-button-group>
            <a-button icon="file-excel" @click="downloadTemplate">{{ $t("模板下载") }}</a-button>
            <a-upload name="file" :showUploadList="false" :customRequest="importExcel">
              <a-button icon="upload">{{ $t("导入") }}</a-button>
            </a-upload>
            <a-button icon="download" @click="exportExcel">{{ $t("导出") }}</a-button>
            <a-button icon="barcode" @click="bulkPrint">{{ $t("批量打印") }}</a-button>
          </a-button-group>
        </a-col>
        <div style="margin-bottom: 12px; float: right">
          <a-button type="primary" icon="plus" style="margin: 0 8px" @click="modalVisible = true">
            {{ $t("添加货品") }}
          </a-button>
        </div>
      </a-row>

      <a-row>
        <a-table
          size="small"
          rowKey="id"
          :columns="columns"
          :dataSource="items"
          :loading="loading"
          :pagination="pagination"
          :scroll="{ x: 1000 }"
          @change="tableChange"
        >
          <div slot="is_active" slot-scope="value">
            <a-tag :color="value ? 'green' : 'red'">{{ value ? $t("激活") : $t("冻结") }}</a-tag>
          </div>
          <div slot="action" slot-scope="value, item">
            <a-button-group>
              <a-button icon="barcode" size="small" @click="labelPrint(item)">{{ $t("打印") }}</a-button>
              <a-popconfirm :title="$t('确定删除吗?')" @confirm="remove(item)">
                <a-button type="danger" icon="delete" size="small">{{ $t("移除") }}</a-button>
              </a-popconfirm>
            </a-button-group>
          </div>
        </a-table>
      </a-row>
    </a-card>

    <a-modal v-model="importLoading" :footer="null" :maskClosable="false" :closable="false">
      <div><a-spin style="margin-right: 12px" />{{ $t("正在导入中, 请等待...") }}</div>
    </a-modal>

    <MaterialSelectModal v-model="modalVisible" @select="add" @confirm="onConfirm" />
  </div>
</template>

<script>
import { materialList, materialAddClient, materialRemoveClient } from "@/api/material";
import { materialsTemplate, clientMaterialImport } from "@/api/import";
import { getLodop } from "@/assets/LodopFuncs";
import { materialsExport } from "@/api/export";
import { exportExcel } from "@/utils/excel";
import columns from "./columns.js";

export default {
  components: {
    CategorySelect: () => import("@/components/CategorySelect/"),
    MaterialSelectModal: () => import("@/components/MaterialSelectModal/"),
  },
  data() {
    return {
      columns,
      clientId: undefined,
      searchForm: { search: "", page: 1, ordering: undefined },
      pagination: { current: 1, total: 0, pageSize: 16 },
      loading: false,
      items: [],

      modalVisible: false,
      importLoading: false,
    };
  },
  computed: {
    materialLabelWidth() {
      return this.$store.state.user.barConfig.materialLabelWidth;
    },
    materialLabelLength() {
      return this.$store.state.user.barConfig.materialLabelLength;
    },
  },
  methods: {
    list() {
      this.loading = true;
      materialList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    add(item) {
      materialAddClient({ id: item.id, client: this.clientId }).then((data) => {
        this.$message.success(this.$t("添加成功"));
        this.$functions.insertItem(this.items, data);
      });
    },
    remove(item) {
      materialRemoveClient({ id: item.id, client: this.clientId }).then(() => {
        this.$message.success(this.$t("移除成功"));
        this.$functions.removeItem(this.items, item);
      });
    },
    async onConfirm(items) {
      for (const item of items) {
        await materialAddClient({ id: item.id, client: this.clientId }).then((data) => {
          this.$functions.insertItem(this.items, data);
        });
      }
    },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    exportExcel() {
      materialsExport(this.searchForm)
        .then((resp) => {
          exportExcel(resp, this.$t("货品列表"));
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
    downloadTemplate() {
      materialsTemplate()
        .then((resp) => {
          exportExcel(resp, this.$t("货品导入模板"));
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
    importExcel(item) {
      let data = new FormData();
      data.append("file", item.file);
      this.importLoading = true;
      setTimeout(() => {
        clientMaterialImport(data, this.clientId)
          .then(() => {
            this.$message.success(this.$t("导入成功"));
            this.list();
          })
          .catch((err) => {
            this.$message.error(err.response.data.detail);
          })
          .finally(() => {
            this.importLoading = false;
          });
      }, 1000);
    },
    labelPrint(item) {
      if (!this.materialLabelLength || !this.materialLabelWidth) {
        this.$message.warning(this.$t("请先在标签配置中设置标签参数!"));
        return false;
      }
      let LODOP = getLodop();
      LODOP.PRINT_INIT("");

      LODOP.SET_PRINT_PAGESIZE(1, `${this.materialLabelLength}mm`, `${this.materialLabelWidth}mm`);
      LODOP.ADD_PRINT_BARCODE(
        `${this.materialLabelWidth * 0.15}mm`,
        `${this.materialLabelLength * 0.15}mm`,
        `${this.materialLabelLength * 0.7}mm`,
        `${this.materialLabelWidth * 0.7}mm`,
        "128A",
        item.number
      );
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 20);

      LODOP.PREVIEW();
    },
    bulkPrint() {
      if (!this.items.length) {
        this.$message.warning(this.$t("暂无打印的数据!"));
        return false;
      }
      if (!this.materialLabelLength || !this.materialLabelWidth) {
        this.$message.warning(this.$t("请先在标签配置中设置标签参数!"));
        return false;
      }
      let LODOP = getLodop();
      LODOP.PRINT_INIT("");
      LODOP.SET_PRINT_PAGESIZE(1, `${this.materialLabelLength}mm`, `${this.materialLabelWidth}mm`);
      this.items.map((item, idx) => {
        LODOP.NewPage();
        LODOP.ADD_PRINT_BARCODE(
          `${this.materialLabelWidth * 0.15}mm`,
          `${this.materialLabelLength * 0.15}mm`,
          `${this.materialLabelLength * 0.7}mm`,
          `${this.materialLabelWidth * 0.7}mm`,
          "128A",
          item.number
        );
        LODOP.SET_PRINT_STYLEA(0, "FontSize", 20);

        // LODOP.ADD_PRINT_BARCODE(38 + idx * (this.materialLabelWidth + 20), 34, this.materialLabelLength, this.materialLabelWidth, "128A", item.number);
      });
      LODOP.PREVIEW();
    },
  },
  mounted() {
    this.clientId = this.$route.query.id;
    if (this.clientId) {
      this.searchForm.client = this.clientId;
    } else {
      this.$router.go(-1);
    }

    this.list();
  },
};
</script>

<style scoped></style>
